import React from "react";
import Layout from "../../components/layout";

const Klienci = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Our Clients",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/klienci/",
      }}
    >
      <section className="container-fluid home_section_3">
        <div className="text-center">
          <h2>Our Clients</h2>
        </div>
        <div className="client_grid">
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_30.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_2.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_3.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_4.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_17.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_16.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_7.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_8.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_9.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_10.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_11.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_12.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_13.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_6.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_14.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_15.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_18.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_19.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_20.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_21.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_22.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_23.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_24.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_25.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_26.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_27.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_28.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_29.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_30.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_31.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_32.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_33.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_34.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_35.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_36.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_37.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_38.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_39.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_50.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_40.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_41.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_42.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_43.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_44.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_45.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_46.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_47.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_48.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_49.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_50.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_51.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_52.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_54.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_55.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_56.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../../assets/img/cooperation/cooperation_logo_57.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Klienci;
